.App {
  text-align: center;
}

.App-header {
  margin: 50px 0;
  font-size: 2em;
}

main {
  margin: 20px 0;
}

.button-row button {
  margin: 10px 0;
}

.record-row .list-group-item b {
  padding-left: 5px;
}

.badge-parent {
  font-size:20px
}

.record-row .badge {
  margin: 0 2px;
}

.record-row .badge svg {
  margin-right:5px;
}

th {
  text-align: left;
}

td {
  text-align: left;
}

.record-row .MuiPaper-root {
  box-shadow: none;
}

.dropdown Button {
  margin: 0 10px 10px;
}

.link-cell a {
  margin-right: 5px;
  font-size: 1.3em;
}

.alias {
  font-style: italic;
  font-size: 0.8em;
}

/*
.badge.PERSON {
  background-color: rgb(24, 116, 24) !important;
}
.badge.NORP {
  background-color: rgb(35, 171, 35) !important;
}
.badge.FAC {
  background-color: rgb(44, 214, 44) !important;
}
.badge.ORG {
  background-color: rgb(50, 248, 50) !important;
}
.badge.GPE {
  background-color: rgb(15, 76, 15) !important;
}
.badge.LOC {
  background-color: rgb(56, 113, 56) !important;
}
.badge.PRODUCT {
  background-color: rgb(73, 150, 73) !important;
}
.badge.EVENT {
  background-color: rgb(109, 213, 109) !important;
}
.badge.WORK_OF_ART {
  background-color: rgb(117, 244, 117) !important;
}
.badge.LAW {
  background-color: rgb(1, 76, 1) !important;
}
.badge.LANGUAGE {
  background-color: rgb(3, 130, 3)!important;
}
.badge.DATE {
  background-color: rgb(2, 244, 2) !important;
}
.badge.TIME {
  background-color: rgb(37, 58, 37) !important;
}
.badge.PERCENT {
  background-color: rgb(82, 126, 82) !important;
}
.badge.MONEY {
  background-color: rgb(102, 177, 102) !important;
}
.badge.QUANTITY {
  background-color: rgb(119, 193, 119) !important;
}
.badge.ORDINAL {
  background-color: rgb(135, 218, 135) !important;
}
.badge.CARDINAL {
  background-color: rgb(142, 234, 142) !important;
}*/