@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');

html body {
  margin: 0;
  font-family: "Roboto", system-ui !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(245,	245,	245	)
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p.input-text {
  text-align: left;
}

div.card-layout {
  text-align: left;
  padding: 2em;
  border: none;
  border-radius: 0;
  margin-bottom: 2em;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

div.card-input textarea {
  border: none;
}

.toolbar {
  margin-top: 1em;
}

div.card-input p.input-text {
  min-height: 6.6em
}


header p.branding {
  font-size: 0.4em;
}

header p.intro {
  font-size: 0.5em;
  color: gray
}

.header-row, .header-link {
  padding-bottom: 2em;
  padding-top: 2em;
}
.header-row h2 {
  text-align: left;
  color: rgb(47, 94, 146);
  font-size: 1.8em;
  font-weight: 200;
}

.header-row h2 img {
  vertical-align: baseline;
  margin-right: 0.3em;
  height: 35px;
}

p.sub-text {
  color: gray
}

div.entity-card {
  /*background: rgb(248, 249, 250);*/
  background-color: white;
  border: 1px solid lightgray;
  border-radius: 5px;
  margin-bottom: 1em;
  padding: 10px 8px;
}

.right .entity-label {
  border-radius: 3px;
  font-size: 0.7em;
  padding: 0.15em;
  color: white;
}

.card-tags .entity-label {
  cursor: help;
}

.right .card-layout {
  border: none;
  background: none;
  box-shadow: none;
  padding-top: 0;
  padding-bottom: 1em;
}

.right h5 {
  text-transform: uppercase;
  font-size: 1em;
}

.record-row em {
  background: yellow;
  border-radius: 2px;
  font-style: normal;
  padding: 0 2px
}

.record-row em.PERSON {
  background-color: rgb(44, 152, 152);
  color: white;
}
.record-row em::after {
  content: attr(class);
  border-radius: 2px;
  font-size: 0.6em;
  background-color: white;
  margin: 0 3px;
  color: black;
  font-style: normal;
  vertical-align: middle;
  padding: 0 1px;
}
.record-row em.GPE::after {
  content: 'GPE';
}
.record-row em.GPE {
  background-color: lightsalmon;
  color: white;
}
.record-row em.PERSON::after {
  content: 'PERSON';
}
.header-row {
  text-align: left;
}
.header-link {
  text-align: right;
}
.header-link a {
  text-decoration: none;
  font-size: 1em;
}
.text-count {
  text-align: right;
}
html body .PERSON {
  background-color: rgb(44, 152, 152);
}
html body .NORP {
  background-color: rgb(149, 149, 70);
}
html body .FAC {
  background-color: lightgreen;
}
html body .ORG {
  background-color: lightpink;
}
html body .GPE {
  background-color: lightsalmon;
}
html body .LOC {
  background-color: lightseagreen;
}
html body .PRODUCT {
  background-color: lightskyblue;
}
html body .EVENT {
  background-color: rgb(221, 175, 69);
}
html body .WORK_OF_ART {
  background-color: lightslategray;
}
html body .LAW {
  background-color: lightsteelblue;
}
html body .LANGUAGE {
  background-color: lightblue;
}
html body .DATE {
  background-color: rgb(175, 240, 128);
}
html body .TIME {
  background-color: rgb(128, 162, 240);
}
html body .PERCENT {
  background-color: rgb(201, 128, 240);
}
html body .MONEY {
  background-color: rgb(128, 240, 178);
}
html body .QUANTITY {
  background-color: rgb(240, 191, 128);
}
html body .ORDINAL {
  background-color: rgb(240, 128, 203);
}
html body .CARDINAL {
  background-color: rgb(128, 135, 240);
}

.entity-sub-row {
  color: #666;
  font-size: 0.8em;
  font-style: italic;
}

.card-tags .entity-label {
  margin-left: 4px;
}

div.progress-info {
  font-size: 0.8em;
}
div.progress div.progress-bar {
  background-color: #aaa !important;
}

mark.highlighted-word {
  background-color: rgb(240, 240, 175);
  border-radius: 3px;
  /*border: 1px solid rgb(190, 190, 114);*/
  padding: 0;
  box-shadow: 0 0px 4px rgba(0, 0, 0, 0.3);
  transition: all ease-in-out 1s;
}

.intro-text {
  text-align: left;
}

.intro-text h2, .intro-text h3 {
  color: rgb(47, 94, 146);
  font-weight: 200;
  margin-bottom: 10px;
  font-size: 1.8em;
}
.intro-text h3 {
  font-size: 1.4em;
  margin-bottom: 20px;
}

.intro-text p {
  font-weight: 200;
}

.intro-text li {
  color: #555;
}

/*
div.progress-bar {
  background-color: grey;
  height: 7px;
  border-radius: 3px;
  position: relative;
}

div.progress-bar div {
  width: 50%;
  height: 100%;
  background: blue;
  background: repeating-linear-gradient(
    45deg,
    blue,
    blue 10px,
    lightblue 10px,
    lightblue 20px
  );
  position: absolute;
  animation: progress-bar-movement 1s infinite;
  animation-timing-function: ease-in-out;
}

@keyframes progress-bar-movement {
  0% {
    background-position-x: -28px;
  }
  50% {
    background-position-x: -14px;
  }
  100% {
    background-position-x: 0px;
  }
}*/